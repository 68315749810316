import routes from "../constants/routes";
import { lazyLoadRoutes } from "../utils/lazy-loader";

export const leaveRoutes = [
  {
    path: routes.leave.leaveType,
    element: lazyLoadRoutes("leave/leave-type/index"),
  },
  {
    path: routes.leave.leaveSetting,
    element: lazyLoadRoutes("leave/leave-setting/list"),
  },
  {
    path: routes.leave.addLeaveSetting,
    element: lazyLoadRoutes("leave/leave-setting/add"),
  },
  {
    path: `${routes.leave.editLeaveSetting}/:id`,
    element: lazyLoadRoutes("leave/leave-setting/edit"),
  },
  {
    path: routes.leave.holidayType,
    element: lazyLoadRoutes("leave/holiday-type/pages/index"),
  },
  {
    path: routes.leave.holiday,
    element: lazyLoadRoutes("leave/holiday/index"),
  },
  {
    path: routes.leave.manualLeave,
    element: lazyLoadRoutes("leave/manual-leave/index"),
  },
  {
    path: routes.leave.leaveApplication,
    element: lazyLoadRoutes("leave/leave-application/pages/LeaveApproval"),
  },
  {
    path: routes.leave.leaveReport,
    element: lazyLoadRoutes("leave/leave-report/pages/leave-report"),
  },
];
