import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Button, FormSelect } from "react-bootstrap";
import {
  getCompanyList,
  updateActiveMonth,
  updateSideBar,
} from "../../store/global/actions";
import { getCookie, setCookie } from "../../utils/cookie-service";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import { logo } from "../../constants/images";
import { UPDATE_ACTIVE_MONTH } from "../../store/mutations";
import { FaHandsHelping } from "react-icons/fa";
import { PiUserSwitchBold } from "react-icons/pi";
import {
  add,
  update,
  getMasterList,
  getList,
  getById,
} from "../../store/global/actions";
import { API_ENDPOINT } from "../../store/api-action";
import { connect } from "react-redux";

function Header(props) {
  const companyList = useSelector((state) => state.global.companyList);
  const companyId = useSelector((state) => state.global.companyId);
  const [detailsList, setDetailsList] = useState(null);
  const authUser = useSelector((state) => state.auth.currentUser);
  const activeSideBar = useSelector((state) => state.global.activeSideBar);
  const activeMonth = useSelector((state) => state.global.activeMonth);
  const dispatch = useDispatch();
  const employeeId = getCookie("hrms-employeeId");

  // Retrieve the roles data of employee.
  const roles = getCookie("hrms-emp-role");

  useEffect(() => {
    if (authUser == "Admin") {
      dispatch(getCompanyList());
    }
  }, [dispatch]);

  const onChange = (e) => {
    const { value } = e.target;
    setCookie("hrms-companyId", value);
    window.location.reload();
  };

  const handleSideBar = () => {
    const newState = !activeSideBar;
    props.showSidevarHandler();
    dispatch(updateSideBar(newState));
    localStorage.setItem("sidebarOpen", JSON.stringify(newState));
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebarOpen");
    if (savedSidebarState !== null) {
      const isSidebarOpen = JSON.parse(savedSidebarState);

      if (isSidebarOpen !== activeSideBar) {
        props.showSidevarHandler();
        dispatch(updateSideBar(isSidebarOpen));
      }
    }
  }, []); // this effect mount on openside bar handle

  const getEmployeeDetails = async () => {
    const { getById } = props;
    if (authUser == "User" && employeeId) {
      const list = await getById(
        `${API_ENDPOINT.employee.getEmployee}/${employeeId}`
      );
      if (list) setDetailsList(list);
    }
  };

  useEffect(() => {
    getEmployeeDetails();
  }, [employeeId]);

  return (
    <header className="topbar">
      <nav className="navbar navbar-expand p-0">
        <div className="mobile-toggle-menu">
          <i className="bx bx-menu"></i>
        </div>
        <div className="sidebar-header">
          <Link to={routes.dashboard}>
            <img src={logo} alt="Nead Logo" className="pe-none" />
          </Link>
        </div>
        <div>
          <i
            className={`fa fa-list cursor-pointer me-2 ${
              activeSideBar == true ? "text-primary shadow-lg " : ""
            }`}
            onClick={handleSideBar}
          ></i>
        </div>
        {authUser == "Admin" && (
          <>
            <div className="search-bar me-2">
              <div className="position-relative">
                <FormSelect
                  className="form-control"
                  value={companyId}
                  onChange={onChange}
                >
                  <option>Select</option>
                  {_.map(companyList && companyList.data, (item, i) => (
                    <option value={item.uuid} key={i}>
                      {item.name}
                    </option>
                  ))}
                </FormSelect>
              </div>
            </div>
            <Link
              to={routes.selectMonths}
              size="sm"
              className="me-2 text-white rounded text-decoration-none"
            >
              <Button size="sm" variant="secondary" className="small-text">
                Select Month
              </Button>
            </Link>
          </>
        )}

        {activeMonth ? (
          <Button
            className="btn btn-sm btn-success heading-font-company"
            onClick={() => dispatch(updateActiveMonth(false))}
          >
            Active Month
          </Button>
        ) : (
          <Button
            className="btn btn-danger btn-sm heading-font-company"
            onClick={() => dispatch(updateActiveMonth(true))}
          >
            Freeze Month
          </Button>
        )}
        <div className="top-menu ms-auto">
          {authUser.userType == "Employee" && (
            <div className="top-menu ms-auto">
              <Link
                to={routes.ess.queryManagement.queryManagement}
                className="text-decoration-none btn btn-primary ms-4 btn-sm small-text"
              >
                <h6 className="mb-0 fw-bold">
                  <FaHandsHelping className="me-2" />
                  Help Desk
                </h6>
              </Link>
            </div>
          )}
        </div>
        <p>{roles}</p>

        <div className="user-box dropdown">
          <a
            className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://images.unsplash.com/photo-1671750747942-e7dc10dd13b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              className="user-img"
              alt="user avatar"
            />
            <div className="user-info ps-3 ">
              <p>{authUser}</p>
              {authUser == "User" && (
                <p style={{ fontSize: "12px" }}>
                  {detailsList?.firstName} &nbsp;
                  {detailsList?.lastName}
                </p>
              )}
            </div>
          </a>

          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <a className="dropdown-item" href="#">
                Profile
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Settings
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Dashboard
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Earnings
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Downloads
              </a>
            </li>
            <li>
              <div className="dropdown-divider mb-0"></div>
            </li>
            <li>
              <Link to="/auth/logout" className="dropdown-item" href="#">
                Logout
              </Link>
            </li>
          </ul>
        </div>
        <a
          href="#"
          className="px-2"
          title={
            authUser.userType === "Admin"
              ? "Switch to Ess User"
              : "Switch to Admin"
          }
        >
          <PiUserSwitchBold style={{ fontSize: "35px" }} />
        </a>
      </nav>
    </header>
  );
}
export default connect((state) => ({ companyId: state.global.companyId }), {
  add,
  update,
  getMasterList,
  getList,
  getById,
})(Header);
