import { useRoutes } from "react-router-dom";
import { lazyLoadRoutes } from "../utils/lazy-loader";
import routes from "../constants/routes";
import { masterRoutes } from "./master-routes";
import { leaveRoutes } from "./leave-routes";
import { shiftRoutes } from "./shift-routes";
import { assetsRoutes } from "./assets-routes";
import { exitRoutes } from "./exit-routes";
import { essExitProcessRoutes } from "./ess-exit-routes";
import { payrollRoutes } from "./payroll-routes";
import { userRoutes } from "./user-routes";
import { useSelector } from "react-redux";
import { reimbursementRoutes } from "./reimbursement-routes";
import { essReimbursementProcessRoutes } from "./ess-reimbursement-routes";
import { tdsRoutes } from "./tds-routes";
import { mailRoutes } from "./mail-routes";
import { essTdsCalculationProcess } from "./ess-tds-routes";
import { essShiftRoutes } from "./ess-shift-routes";
import { essAttendanceLeaveRoutes } from "./ess-attendance-leave-routes";
import { queryRoutes } from "./query-routes";
import { essQueryRoutes } from "./ess-query-routes";
import { controlRoutes } from "./controls-routes";
import { employeeConfirmDaysRoutes } from "./employee-confirmdays-routes";
import { monthlyCalculationRoutes } from "./monthly-calculation-routes";
import { loanAndAdvanceRoutes } from "./loan-advance-routes";
import { SalaryProcessRoutes } from "./salary-process-routes";
import { essLoanRoutes } from "./ess-loan-routes";
import { onBoardingRoutes } from "./onboarding-routes";
import { essOnBoardingRoutes } from "./ess-onboarding-routes";
import { salarySheetRoutes } from "./salary-sheet-routes";

export function AppRoutes() {
  const authUser = useSelector((state) => state.auth);

  const adminRoutes = [
    {
      path: "/",
      children: [
        {
          index: true,
          element: lazyLoadRoutes("dashboard/pages/dashboard"),
        },
        {
          path: "about",
          element: lazyLoadRoutes("about"),
        },
        {
          path: "jobs",
          element: lazyLoadRoutes("jobs"),
        },
        {
          path: "blog",
          element: lazyLoadRoutes("blog"),
        },
        {
          path: "privacy-policy",
          element: lazyLoadRoutes("privacy-policy"),
        },
        {
          path: "terms-of-service",
          element: lazyLoadRoutes("terms-of-service"),
        },
        {
          path: "auth/logout",
          element: lazyLoadRoutes("auth/logout"),
        },
        {
          path: routes.forgotPassword,
          element: lazyLoadRoutes("shared/auth/forgot-password"),
        },
        {
          path: routes.resetPassword,
          element: lazyLoadRoutes("shared/auth/reset-password"),
        },
        {
          path: routes.dashboard,
          element: lazyLoadRoutes("dashboard"),
        },
        {
          path: routes.employee.listEmployee,
          element: lazyLoadRoutes("employee/list"),
        },
        {
          path: routes.employee.addEmployee,
          element: lazyLoadRoutes("employee/add"),
        },
        {
          path: `${routes.employee.addEmployee}/:id`,
          element: lazyLoadRoutes("employee/add"),
        },
        {
          path: routes.employee.listEmployeeNew,
          element: lazyLoadRoutes("employee/list"),
        },
        {
          path: routes.employee.addEmployeeNew,
          element: lazyLoadRoutes("employee/add"),
        },
        {
          path: `${routes.employee.addEmployeeNew}/:id`,
          element: lazyLoadRoutes("employee/add"),
        },
        {
          path: routes.attendance.attendanceList,
          element: lazyLoadRoutes("attendance/pages/index"),
        },
        {
          path: routes.attendance.attendanceLog,
          element: lazyLoadRoutes("attendance/pages/attendance-log"),
        },
        {
          path: routes.attendance.LogImport,
          element: lazyLoadRoutes("attendance/pages/log-import"),
        },
        {
          path: routes.attendance.autoAttendance,
          element: lazyLoadRoutes("attendance/pages/auto-attendance"),
        },
        {
          path: routes.attendance.monthlyLeavereport,
          element: lazyLoadRoutes("attendance/monthly-leave-report"),
        },
        {
          path: routes.selectMonths,
          element: lazyLoadRoutes("select-month/pages/select-month"),
        },
        ...masterRoutes,
        ...leaveRoutes,
        ...shiftRoutes,
        ...assetsRoutes,
        ...payrollRoutes,
        ...userRoutes,
        ...exitRoutes,
        ...reimbursementRoutes,
        ...tdsRoutes,
        ...mailRoutes,
        ...queryRoutes,
        ...controlRoutes,
        ...employeeConfirmDaysRoutes,
        ...monthlyCalculationRoutes,
        ...loanAndAdvanceRoutes,
        ...SalaryProcessRoutes,
        ...onBoardingRoutes,
        ...salarySheetRoutes,
      ],
    },
  ];

  const essRoutes = [
    {
      path: "/",
      children: [
        {
          index: true,
          element: lazyLoadRoutes("ess/dashboard/pages/dashboard"),
        },
        {
          path: "auth/logout",
          element: lazyLoadRoutes("auth/logout"),
        },
        {
          path: routes.ess.dashboard,
          element: lazyLoadRoutes("ess/dashboard"),
        },

        {
          path: routes.ess.profile,
          element: lazyLoadRoutes("ess/profile/profile"),
        },

        {
          path: routes.ess.employeeEdit,
          element: lazyLoadRoutes("ess/profile/employee-details-edit"),
        },

        {
          path: routes.ess.statutoryEdit,
          element: lazyLoadRoutes("ess/profile/statutory-edit"),
        },
        {
          path: routes.ess.personalDetailsEdit,
          element: lazyLoadRoutes("ess/profile/personal-details-edit"),
        },
        {
          path: routes.ess.qualificationEdit,
          element: lazyLoadRoutes("ess/profile/qualification-edit"),
        },
        {
          path: routes.ess.familymembersEdit,
          element: lazyLoadRoutes("ess/profile/family-members-edit"),
        },
        {
          path: routes.ess.assets.list,
          element: lazyLoadRoutes("ess/assets/pages/my-assets"),
        },
        {
          path: routes.ess.assets.request,
          element: lazyLoadRoutes("ess/assets/pages/request-assets"),
        },
        {
          path: routes.ess.assets.assetsApprovalList,
          element: lazyLoadRoutes("ess/assets/pages/assets-approval-list"),
        },
        ...essExitProcessRoutes,
        ...essReimbursementProcessRoutes,
        ...essTdsCalculationProcess,
        ...essShiftRoutes,
        ...essAttendanceLeaveRoutes,
        ...essQueryRoutes,
        ...essLoanRoutes,
        ...essOnBoardingRoutes,
      ],
    },
  ];

  const companySelectRoutes = [
    {
      path: "/",
      children: [
        {
          index: true,
          element: lazyLoadRoutes("auth/select-company"),
        },
        {
          path: "auth/logout",
          element: lazyLoadRoutes("auth/logout"),
        },
        {
          path: "masters/company-new",
          element: lazyLoadRoutes("masters/company-new/list"),
        },
      ],
    },
  ];

  const companyId = useSelector((state) => state.global.companyId);
  const adminRoutesList =
    companyId !== null ? adminRoutes : companySelectRoutes;
  const appRoutes =
    authUser.currentUser == "Admin" ? adminRoutesList : essRoutes;
  return useRoutes(appRoutes);
}
